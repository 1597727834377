<template>
	<section class="referral-card__container text-center">
		<div class="referral-card bg-arnold h-100" :class="{'referral-card--featured': planName === 'Team'}">
			<h3 class="referral-card__plan-name bg-lange sz-4 p-2">{{ planName }}</h3>

			<div class="pb">
				<div class="flex flex--center">
					<h2 class="monthly-amount matins sz-9">{{ monthlyAmount }}</h2>
				</div>
				<div>
					<p class="sz-2 italic">30-day free trial</p>
					<router-link :to="getPlanLink()" class="button button--primary bg-deep sz-3">
						Try It free
					</router-link>
				</div>

				<button class="b6:hide mt button--no-style" @click="expanded = !expanded">
					<div class="deep">Plan Features</div>
					<c-expand-toggle-button	:is-button="false" :expanded="expanded" />
				</button>

				<c-expandable :expanded="expanded">
					<slot />
				</c-expandable>
			</div>
		</div>
	</section>
</template>

<script>
	import CExpandToggleButton from '@cognitoforms/shared-components/src/components/interaction/ExpandToggleButton.vue';
	import CExpandable from '@cognitoforms/shared-components/src/components/interaction/Expandable.vue';

	export default {
		components: { CExpandToggleButton, CExpandable },
		props: {
			planName: { type: String, default: null },
			monthlyAmount: { type: String, default: null }
		},
		data() {
			return {
				expanded: false
			};
		},
		methods: {
			getPlanLink() {
				return '/signup?source=freetrial&returnUrl=' + encodeURIComponent('/organization/subscriptionsettings?plan=' + this.planName);
			}
		}
	};
</script>

<style lang="scss">
// For dl styles check Referral.vue

// prerender override
.referral-card--featured.referral-card--featured {
	@include b-up($b6) {
		height: calc(100% + 17px);
		margin-top: -17px;
	}
}

.referral-card {

	&--featured {
		background-color: white;
		box-shadow: -1px 0 .5rem $brady, 1px 0 .5rem $brady;

		.referral-card__plan-name {
			background: $matins;
			color: white;
			font-weight: $heavy;

			&::before {
				display: block;
				content: 'Best Value';
				font-size: modular-scale(2);
				font-weight: $light;
			}
		}

		.button--primary {
			background: $jaywalk;
		}
	}

	@include b-up($b6) {

		&__plan-name {
			position: sticky;
			top: 0;
		}

		.expandable-container {
			max-height: none !important;
		}
	}

	@include b-down($b6) {

		.expandable-container:not(.is-active) {
			max-height: 0 !important;
		}
	}

	h2.monthly-amount {

		&::after {
			vertical-align: super;
			content: '/mo';
			font-size: .25em;
		}

		&::before {
			vertical-align: super;
			content: '$';
			font-size: .55em;
		}
	}
}

</style>